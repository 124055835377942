import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import Section from 'src/components/Section';
import { getJoinedArrayByComma } from 'src/utils/helpers';
import {
  categoryFields,
  categoryFieldsLabels,
} from 'src/components/MultistepForm/shared/constants';
import { mediaQuery } from 'src/theme/breakpoints';
import FormTripPlan from 'src/components/Form/FormTripPlan';

const Wrapper = styled.div`
  box-sizing: border-box;
  margin-top: 60px;

  ${mediaQuery('md')} {
    padding: 0 40px;
  }
`;

const Text = styled.p`
  margin-top: 0;
  text-align: center;
`;

const Main = styled.div`
  align-items: center;
  display: grid;
  flex-direction: column;
  grid-column-gap: 60px;
  grid-template-columns: 1fr;
  justify-content: space-between;

  ${mediaQuery('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const List = styled.dl`
  display: grid;
  font-size: 14px;
  grid-row-gap: 20px;
  grid-template-columns: 0.5fr 1fr;

  ${mediaQuery('md')} {
    font-size: 16px;
    grid-row-gap: 10px;
    grid-template-columns: max-content auto;
  }
`;

const ListItemTitle = styled.dt`
  grid-column-start: 1;
`;
const ListItemData = styled.dd`
  font-weight: 700;
  grid-column-start: 2;
`;

const AdditionalInfo = styled.p`
  font-size: 16px;
  margin-top: 30px;
`;

interface FormSummaryProps {
  formFields: any;
}

const FormSummary: FC<FormSummaryProps> = ({ formFields }) => (
  <Wrapper>
    <Section title="Visvien nerandi svajonių kelionės?">
      <Text>
        Užpildyk anketą žemiau ir gauk individualų kelionės planą į savo el.
        pašto dėžutę
      </Text>
      <Main>
        {formFields && (
          <div>
            <List>
              {Object.keys(formFields).map(key => (
                <Fragment key={key}>
                  <ListItemTitle>{categoryFieldsLabels[key]}:</ListItemTitle>
                  <ListItemData>
                    {key === categoryFields.PEOPLE
                      ? formFields[key]
                      : getJoinedArrayByComma(formFields[key])}
                  </ListItemData>
                </Fragment>
              ))}
            </List>
            <AdditionalInfo>
              Tavo maršrutas beveik paruoštas. Tiesiog užpildyk savo
              kontaktinius duomenis ir individualų kelionės maršrutą mes
              atsiųsime tiesiai į tavo el. pašto dėžutę.
            </AdditionalInfo>
          </div>
        )}
        <FormTripPlan additionalData={formFields} />
      </Main>
    </Section>
  </Wrapper>
);

export default FormSummary;
