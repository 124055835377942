import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { NoResultsIcon } from 'src/assets/icons';
import Heading, { HeadingVariant, HeadingScope } from 'src/components/Heading';
import { mediaQuery } from 'src/theme/breakpoints';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
`;

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin: 10px auto;
  max-width: 400px;
  text-align: center;
`;
const ImageWrapper = styled.div`
  margin-top: -100px;
  width: 100%;

  ${mediaQuery('md')} {
    width: 400px;
  }

  ${mediaQuery('lg')} {
    margin-top: -140px;
  }
`;

const Wrapper404 = styled.div`
  width: 100%;

  ${mediaQuery('md')} {
    width: 500px;
  }
`;

interface NotFoundProps {
  title: string;
  text: string;
  is404?: boolean;
}

const NotFound: FC<NotFoundProps> = ({ title, text, is404 }) => (
  <Wrapper>
    {is404 ? (
      <Wrapper404>
        <StaticImage
          src="../../assets/images/404.png"
          alt="Puslapis nerastas"
        />
      </Wrapper404>
    ) : (
      <ImageWrapper>
        <NoResultsIcon />
      </ImageWrapper>
    )}
    <InfoWrapper>
      <HeadingScope>
        <Heading variant={HeadingVariant.BRAVO}>{title}</Heading>
      </HeadingScope>
      <Text>{text}</Text>
    </InfoWrapper>
  </Wrapper>
);

export default NotFound;
