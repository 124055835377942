import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { colors } from 'src/theme/colors';

const Field = styled.div<{
  isHidden: boolean;
}>`
  display: ${props => (props.isHidden ? 'none' : 'flex')};
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;
const Label = styled.label`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
`;

const Input = styled.input<{
  isError?: boolean;
}>`
  border: 1px solid
    ${props => (props.isError ? colors.orange500 : colors.white)};
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: inherit;
  height: 36px;
  padding: 0 10px;
`;

interface FormFieldProps {
  name: string;
  id: string;
  label: string;
  type?: string;
  value?: string;
  placeholder?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  name,
  id,
  type = 'text',
  label,
  value,
  placeholder,
}) => {
  const [field, meta] = useField({ name });

  return (
    <Field isHidden={type === 'hidden'}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        {...field}
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        // isError={meta?.error && meta?.touched}
      />
    </Field>
  );
};

export default FormField;
