import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { object, number, string } from 'yup';

import FormField from 'src/components/Form/FormField/FormField';
import FormTextarea from 'src/components/Form/FormTextarea/FormTextarea';
import { getEncodedData } from 'src/utils/helpers';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';
import { baseButtonStyles } from 'src/components/Button/Button';
import {
  categoryFieldsLabels,
  initialCategoryFields,
} from 'src/components/MultistepForm/shared/constants';

const Wrapper = styled.div<{
  isHidden?: boolean;
}>`
  background-color: ${colors.blue100};
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: ${props => props.isHidden && 'none'};
  margin: 20px auto 0;
  max-width: 360px;
  padding: 20px;
  width: 100%;

  ${mediaQuery('md')} {
    padding: 30px 30px 40px;
  }
`;

const Button = styled.button`
  ${baseButtonStyles}
  width: 100%;
`;

const SuccessMessage = styled.span`
  text-align: center;
`;

const Info = styled.p`
  font-size: 10px;
  margin: 10px 0 0;
`;

enum FormStatus {
  LOADED = 'loaded',
  SUBMITTED = 'submitted',
  FAILURE = 'failure',
  SUCCESS = 'success',
}

const validationSchema = object({
  name: string().required('Required'),
  email: string().email().required('Required'),
  phone: number().required('Required'),
  message: string().required('Required'),
});

interface FormTripPlanProps {
  additionalData?: Record<string, unknown>;
  isHidden?: boolean;
}

const FormTripPlan: React.FC<FormTripPlanProps> = ({
  additionalData = {},
  isHidden,
}) => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    ...initialCategoryFields,
  };

  const [formStatus, setFormStatus] = useState<string>(FormStatus.LOADED);

  const onSubmit = (values, actions): void => {
    const stringifiedData = getEncodedData({
      'form-name': 'trip-plan-form',
      ...values,
      ...additionalData,
    });

    setFormStatus(FormStatus.SUBMITTED);

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: stringifiedData,
    })
      .then(() => {
        actions.resetForm();
        setFormStatus(FormStatus.SUCCESS);
      })
      .catch(() => setFormStatus(FormStatus.FAILURE));
  };

  return (
    <Wrapper isHidden={isHidden}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form
          name="trip-plan-form"
          netlify-honeypot="bot-field"
          data-netlify={true}
        >
          <input
            type="hidden"
            name="subject"
            value="Unikalaus maršruto pageidavimas"
          />
          <FormField name="name" id="name" label="Vardas" />

          <FormField name="email" id="email" label="El. paštas" />

          <FormField type="tel" name="phone" id="phone" label="Telefonas" />

          <FormTextarea
            name="message"
            id="message"
            label="Papildomi pageidavimai"
            placeholder="Papildykite savo pasirinkimus specialiais pageidavimais"
            rows={5}
          />

          {Object.keys(initialCategoryFields).map(key => (
            <FormField
              type="hidden"
              key={key}
              id={key}
              name={key}
              label={categoryFieldsLabels[key]}
            />
          ))}

          {formStatus !== FormStatus.SUCCESS ? (
            <>
              <Button type="submit">Suformuoti kelionę</Button>
              <Info>Su jumis susisieksime per artimiausias 12 valandų</Info>
            </>
          ) : (
            <SuccessMessage>
              Unikalios kelionės užklausa išsiųsta! Kelionės pasiūlymą gausi į
              savo el. pašto dėžutę artimiausiu metu.
            </SuccessMessage>
          )}
        </Form>
      </Formik>
    </Wrapper>
  );
};

export default FormTripPlan;
