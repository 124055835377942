import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;
const Label = styled.label`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
`;

const Input = styled.textarea`
  border: 1px solid ${colors.white};
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: inherit;
  min-height: 60px;
  padding: 20px 10px;

  ${mediaQuery('lg')} {
    min-width: 300px;
  }
`;

interface FormTextareaProps {
  name: string;
  id: string;
  label: string;
  placeholder?: string;
  rows?: number;
}

const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  id,
  label,
  rows = 2,
  placeholder,
}) => {
  const [field, meta] = useField({ name });

  return (
    <Field>
      <Label htmlFor={id}>{label}</Label>
      <Input
        {...field}
        rows={rows}
        name={name}
        id={id}
        placeholder={placeholder}
        // style={{
        //   border:
        //     meta?.error && meta?.touched && `1px solid ${colors.orange500}`,
        // }}
      />
    </Field>
  );
};

export default FormTextarea;
