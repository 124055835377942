import React, { useEffect, useState } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import styled from 'styled-components';

import {
  categoryFields,
  links,
  storageKeys,
} from 'src/components/MultistepForm/shared/constants';
import { getFromStorage } from 'src/utils/helpers';
import {
  clearFormDataFromStorage,
  isAllFieldsFilled,
} from 'src/components/MultistepForm/shared/helpers';
import Products from 'src/components/Products/Products';
import FormNavigation from 'src/components/MultistepForm/FormNavigation/FormNavigation';
import FormSummary from 'src/components/MultistepForm/FormSummary';
import FormBackground from 'src/components/MultistepForm/FormBackground';
import Main from 'src/components/Main';
import { baseButtonStyles } from 'src/components/Button/Button';
import FormTripPlan from 'src/components/Form/FormTripPlan';
import SEO from 'src/components/Seo/Seo';

const Button = styled.button`
  ${baseButtonStyles}
  margin: 10px auto;
  width: 280px;
`;

enum PlanStatusEnum {
  IS_LOADING = 'loading',
  NOT_STARTED = 'not-started',
  NOT_COMPLETED = 'not-completed',
  IS_COMPLETED = 'completed',
}

interface UniqueTripPlanProps {
  data: any;
}

const UniqueTripPlan: React.FC<UniqueTripPlanProps> = ({ data }) => {
  const {
    wpPage: { seo },
  } = data;

  const [filters, setFilters] = useState({});
  const [formFields, setFormFields] = useState({});
  const [planStatus, setPlanStatus] = useState<string>(
    PlanStatusEnum.IS_LOADING
  );

  useEffect(() => {
    // 1. get values from the storage
    const filledFields = getFromStorage(storageKeys.FORM_VALUES);

    // 2. get status by checking values:

    // 2.1) if doesn't exist, set status (Fill_FORM)  that it needs to fill the form with CTA
    if (!filledFields) {
      setPlanStatus(PlanStatusEnum.NOT_STARTED);
      return;
    }

    // 2.2) if does exist, but isn't fully completed, set status (NOT_COMPLETED) that the form needs to completed
    const parsedFields = JSON.parse(filledFields);
    setFormFields(parsedFields);

    const isFormCompleted = isAllFieldsFilled(parsedFields);
    if (!isFormCompleted) {
      setPlanStatus(PlanStatusEnum.NOT_COMPLETED);
      return;
    }

    // 2.3) if does exist and it's fully completed, set STATUS (COMPLETED) and show filtered products
    const filterBy = {
      [categoryFields.COUNTRIES]: parsedFields[categoryFields.COUNTRIES],
      [categoryFields.ACTIVITIES]: parsedFields[categoryFields.ACTIVITIES],
    };

    setPlanStatus(PlanStatusEnum.IS_COMPLETED);
    setFilters(filterBy);
  }, []);

  const loadingJSX = (): JSX.Element => {
    return (
      <>
        <h1>Loading...</h1>
        <FormTripPlan isHidden />
      </>
    );
  };

  const notStartedJSX = (): JSX.Element => {
    return (
      <>
        <h1>Forma nepradėta pildyti</h1>
        <Link to={links.TRIP_FORM}>Pildyti formą</Link>
      </>
    );
  };

  const notCompletedJSX = (): JSX.Element => {
    return (
      <>
        <h1>Pabaikite pildyti formą</h1>
        <Link to={links.TRIP_FORM}>forma</Link>
      </>
    );
  };

  const CompletedJSX = (): JSX.Element => {
    const refillForm = (): void => {
      clearFormDataFromStorage();
      navigate(links.TRIP_FORM);
    };

    return (
      <FormBackground>
        <FormNavigation
          title="Rekomenduojamos kelionės"
          backActive={false}
          isCompleted={true}
        />
        <Main>
          <Products filters={filters} columns={4} />
          <Button onClick={refillForm}>Pildyti iš naujo</Button>
          <FormSummary formFields={formFields} />
        </Main>
      </FormBackground>
    );
  };

  const renderPlanByStatus = (): JSX.Element | undefined => {
    switch (planStatus) {
      case PlanStatusEnum.IS_LOADING:
        return loadingJSX();
      case PlanStatusEnum.NOT_STARTED:
        return notStartedJSX();
      case PlanStatusEnum.NOT_COMPLETED:
        return notCompletedJSX();
      case PlanStatusEnum.IS_COMPLETED:
        return CompletedJSX();
      default:
        break;
    }
  };
  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        image={seo?.opengraphImage?.localFile}
      />

      {renderPlanByStatus()}
    </>
  );
};

export const query = graphql`
  query UniqueTripPlan {
    wpPage(slug: { eq: "keliones-planas" }) {
      ...YoastSeoPage
    }
  }
`;

export default UniqueTripPlan;
