import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  getMappedProducts,
  getStringifiedCategoryValues,
} from 'src/components/Product/shared/helpers';
import { isArrayEmpty } from 'src/utils/helpers';
import TravelCard from 'src/components/TravelCard';
import {
  categoryFields,
  links,
  neutralOptionValue,
} from 'src/components/MultistepForm/shared/constants';
import { CategoryFields } from 'src/components/MultistepForm/shared/types';
import NotFound from 'src/components/NotFound';
import Grid from 'src/components/Grid/Grid';

interface ProductsProps {
  filters?: CategoryFields | Record<string, never>;
  columns: number;
}

const Products: React.FC<ProductsProps> = ({ filters, columns }) => {
  const {
    allWpProduct: { nodes: productsData },
  } = useStaticQuery<GatsbyTypes.ProductsQuery>(graphql`
    query Products {
      allWpProduct {
        nodes {
          ... on WpSimpleProduct {
            name
            slug
            regularPrice
            salePrice
            productFields {
              days
              people
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 720)
                }
              }
            }
            productCategories {
              nodes {
                slug
                name
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
            productTags {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `);

  const products = getMappedProducts(productsData);

  const filteredProducts = useMemo(() => {
    if (!filters) return products;

    return products.filter(product => {
      let contains = true;

      //  Map through object of category filters
      Object.keys(filters).forEach(filterCategory => {
        const productCategory = product.productCategories[filterCategory];

        // 1. Stop executing if product category doesn't exist
        if (!productCategory) return;

        // 2. get filter category values
        const filterCategoryValues = filters[filterCategory];

        // 3. Get product filter category values
        const productCategoryValues = product.productCategories[
          filterCategory
        ]?.map(val =>
          filterCategory !== categoryFields.DAYS &&
          filterCategory !== categoryFields.PEOPLE
            ? val.name
            : String(val)
        );

        // 4. Check if filter category has values and contains still true
        if (!isArrayEmpty(filterCategoryValues) && contains) {
          // 5. Check if product contains any filtered category value
          contains =
            filterCategoryValues.includes(neutralOptionValue) ||
            productCategoryValues.some(value =>
              filterCategoryValues.includes(value)
            );
        }
      });

      return contains;
    });
  }, [filters]);

  return !isArrayEmpty(filteredProducts as []) ? (
    <Grid columns={columns}>
      {filteredProducts.map(product => (
        <TravelCard
          key={product.slug}
          image={product.image}
          url={`${links.TRIPS}/${product.slug}`}
          tags={product.productTags}
          location={getStringifiedCategoryValues(
            product.productCategories[categoryFields.COUNTRIES]
          )}
          duration={`${product.productFields.days} d.`}
          title={product.name}
          regularPrice={product.regularPrice}
          salePrice={product.salePrice}
        />
      ))}
    </Grid>
  ) : (
    <NotFound
      title="Kelionių nerasta :("
      text="Šiuo metu neturime kelionės, kuri atitiktų tavo norus. Užpildyk formą iš naujo arba užsisakyk individualų kelionės planą."
    />
  );
};

export default Products;
